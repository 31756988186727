.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.buttonShadowWhite {
  border-radius: 4px;
  color: #000;
  background: var(--pure-white, #FFF);
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
}

.buttonShadowBlue {
  color: #fff;
  border-radius: 4px;
  background: var(--brand-primary, #0F6CBD);
  box-shadow: 0px 0px 0px 1px #5E5ADB, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
}

.datamap-state{
  fill: rgb(251, 249, 182)
}


table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

/* td, th {
  

} */
.backGrey:nth-child(even) {
  background-color: #dddddd;
}
.tableHdr{
  padding: 6px !important;
  /* border: 1px solid #dddddd; */

  text-align: left;
}
.tableHdr1{
  padding: 8px !important;
  background-color: #fff;
  height: 60px;
  /* border: 1px solid #dddddd; */

  text-align: left;
}

.equal-row{
  height: 60px;
  border-bottom: dashed 1px rgb(197, 196, 196);
}

.equal-rowone{
  height: 120px;
  border-bottom: dashed 1px rgb(197, 196, 196);
}.equal-rowtwo{
  height: 180px;
  border-bottom: dashed 1px rgb(197, 196, 196);
}
.custom-header-class {
  writing-mode: vertical-rl; /* vertical right-to-left */
  transform: rotate(180deg);
}


 /* .ag-header, .ag-pivot-off, .ag-header-allow-overflow{
  height: 217px !important;
  min-height: 101px;
} */
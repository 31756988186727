body {
    margin: 0;
    font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background: white;
    font-size: 24px;
}
.app {
    background: whitesmoke;
}
h1 {
    color: #165a72;
    margin: 50px auto;
    font-size: 40px;
}
.menu1 {
    margin-bottom: 40px;
    margin-top: 10px;
}
h2 {
    color: #165a72;
}
.sidebar{
    max-width: 200px;
}
.toggle {
    display: inline-block;
    position: relative;
    width: 45px;
    height: 25px;
  }
  
  .toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right,#fff0e6, #b3d1ff);
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 21px;
    width: 22px;
    left: 0px;
    bottom: 2px;
    background-color: rgb(252, 245, 252);
    transition: 0.4s;
    border-radius: 50%;
  }
  
  .on .slider {
    /* background-color: #2196F3; */
    background-image: linear-gradient(to right, #b3d1ff, #fff0e6);
  }
  
  .on .slider:before {
    transform: translateX(22px);
  }
  
  .off .slider {
    background-color: #ccc;
  }
  
  .off .slider:before {
    transform: translateX(0);
  }



